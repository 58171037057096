#clue {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
    background-color: #0000BB;
}

#clue-text{
    color: white;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    padding: 0.5em;
    margin-top: -1vh;
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    
    
    padding: 0 0.4vh 0 0.4vh;
       
}

.arrow:hover, .arrow:active {
    background-color: blue;

}

.arrow-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
}

.arrow-box:active {
    top: 0.5vh;
}

.arrow img {
    position: relative;
    width: 1.5em;
}

.arrow-left{
    margin-right: auto;
}

.arrow-right{
    margin-left: auto;
}