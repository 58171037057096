.dashboard{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    bottom: 0;
    justify-content: center;

}

#dashboard-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: 4vh;
    margin-bottom: 1vh;
}

#dashboard-buttons .button{
    display: inline-flex;
    margin-right: 1vh;
}

.dashboard-categories {
    overflow-y: auto;
    white-space: nowrap;
    width: 95%;
    max-height: 25vh;
    margin-left: 2.25vh;
    margin-bottom: 1vh;
    margin-top: 1vh;
    cursor: default;
}

.dashboard-category-progress-bar{
    width: 19vh;
}

.dashboard-category-line{
    display: flex;
    align-items: center;
    margin: 0.5vh;
    padding: 0.25vh;
    border-radius: 0.2em; 
    max-width: 55vh;
}

.dashboard-category-line:hover{
    outline: 0.5vh solid rgba(255, 255, 255, 0.25);;
}

.dashboard-category-line-selected, .dashboard-category-line-selected:hover{
    outline: 0.5vh solid white;
}

.dashboard-category-name{
    color: white;
    font-size: smaller;
    display: inline-block;
    
}

.dashboard-categories .progress-bar {
    margin: 0.5vh 1.5vh 0.5vh 0.5vh; 
}


.dashboard-categories .progress-bar-square{
    height: 1.5vh;

}

