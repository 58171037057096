#answer-columns {
    display: flex;
    justify-content: center; 
}

#answer-column1 {
    display: flex;
    flex: 1;
    justify-content: center;
    
}

.answer-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    aspect-ratio: 1 / 1;

    margin-top: -2vh;
    margin-left: auto;
    margin-right: 1vh;

    background-color: #0000BB;
    
    padding: 1vh;
    border-radius: 0.2em;
}

.answer-icon-gold {
    background-color: orange;
}

.small-icon{
    width: 1.1em;
}

#answer-column3 {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

#answer-box {
    display: flex;
    align-items: center;
    text-align: center;

    color: black;
    font-size: 1.2em;
    font-weight: bold;
    padding: 1vh;
    margin-top: -2vh;
    background-color: orange;
    border-radius: 0.2em;

}

#answer a {
    color: #0000BB;
}