#menu-screen {
    color: white;
}

#title {
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-top: 4vh;
    margin-bottom: 6vh;    
}

#count-columns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
}

.count {
    margin: auto;
    font-size: 0.8em;
    
}

#title-text {
    font-size: 2em;
    background-color: #0000BB;
    padding: 3vh;
    border-radius: 0.2em;
    text-align: center;
}

#description {
    display: flex;
    justify-content: center;
    align-items: center;
}

#range{
    display: flex;
    justify-content: center;
    align-items: center;
}

select{
    height: 3em;
    margin: 1vh;
    background-color: #0000BB;
    color: white;
    border: none;
    border-radius: 0.2em;

}

#go {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

#go-text {
    background-color: #0000BB;
    font-size: 2em;
    padding: 3vh;
    border-radius: 0.2em;
    cursor: pointer;
}