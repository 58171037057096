
html {
    height: 100%;
}

body{
    font-family: Georgia;
    background-color: #000022;
    overflow: hidden;
    height: 100%;
}

#root {
    height: 100%;
}

.app {
    height: 100%;
}

#quiz {
    height: 100%;
}


a{
    color: cyan;
    
}







.progress-bar-square{
    display: inline-block;
    height: 2vh;
    aspect-ratio: 1 / 1;
    margin: 0.5vh;

    background-color: #000033;
    border: 0.5vh solid #1111ff;
}


.progress-bar-square-incorrect{
    background-color: #1111ff;
    border: 0.5vh solid #1111ff;
}
.progress-bar-square-correct{
    background-color: orange;
    border: 0.5vh solid orange;
}

.progress-bar-square-selected{
    border: 0.5vh solid white;
}




























