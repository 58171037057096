#input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    height: 8vh;
    
}

#input-column1 {
    display: flex;
    justify-content: center;  
    align-items: center;  
    flex: 1;
}



#input-column2 {

}



#input-box{
    height: 2em;
    font-family: Georgia;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    background-color: transparent;
    border: 0.2em solid blue;
    color: white;
    border-radius: 0.2em;
}

#input-box[readonly] {
    border: transparent;
    outline: transparent;
}



#input-column3 {
    display: flex;
    justify-content: center;  
    align-items: center;  
    flex: 1;
}