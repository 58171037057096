.button{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    max-width: fit-content;

    background-color: #0000BB;
    color: white;
    padding: 0 1vh 0 1vh;
    font-weight: bold;
    

    user-select: none;
    border-radius: 0.2em;
}

.button img {
    height: 60%;
}

.button * {
    padding: 0.5vh;
}


.button:active, .button:hover{
    background-color: blue;
}

.button:active{
    top: 0.5vh;
}

