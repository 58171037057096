
#header {
    display: flex;
    justify-content: center;    
}

#header-column1{
    display: flex;
    justify-content: center;  
    align-items: center;  
    flex: 1;
}

#header-column2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#category{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    height: 12vh;
    aspect-ratio: 3 / 1 ;

    background-color: #0000BB;
    padding: 0.5em;
    margin-top: 0.5em;
    border-radius: 0.2em;
}

#category-name{
    text-align: center;
    color: white;
    font-size: large;
    font-weight: bold;
    
}

#category-comment{
    text-align: center;
    color: white;
    font-size: 0.8em;
}

#year{
    position: absolute;
    bottom: 0;
    right: 0;

    font-size: 0.8em;
    color: white;
    opacity: 0.5;
    margin: 0.5vh;
}

.progress-bar{
    display: flex;
    border-radius: 0.2em;  

}

#header-column2 .progress-bar{
    margin-top: -2vh;
    background-color: #000033;
    padding: 0.25vh;
    z-index: 0;
}

#header-column3{
    display: flex;
    justify-content: center;    
    align-items: center;  
    flex: 1;
}
