#category-selection-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center  ;
    margin-top: 2em;
}

.category-selection-category {
    display: flex;
    height: 5em;
    width: 7.5em;
    background-color: #0000BB;
    margin: 2%;
    border-radius: 0.2em;
    color: white;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    padding: 5%;
    justify-content: center;
    align-items: center;
    cursor: default;

}